import React, {Component} from "react";
import {API} from "aws-amplify";
import {Link} from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";
import {PageHeader, ListGroup, ListGroupItem, Grid, Row, Col} from "react-bootstrap";
import "./Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }


    try {
      const notes = await this.notes();
      const sources = await this.sources();
      this.setState({notes, sources});
    } catch (e) {
      alert(e);
    }

    this.setState({isLoading: false});
  }

  notes() {
    return API.get("notes", "/notes");
  }

  sources() {
    return API.get("sources", "/sources");
  }

  renderNotesList(notes) {
    return [{}].concat(notes).map(
      (note, i) =>
        i !== 0
          ? <LinkContainer
            key={note.noteId}
            to={`/notes/${note.noteId}`}
          >
            <ListGroupItem header={note.content.trim().split("\n")[0]}>
              {"Created: " + new Date(note.createdAt).toLocaleString()}
            </ListGroupItem>
          </LinkContainer>
          : <LinkContainer
            key="new"
            to="/notes/new"
          >
            <ListGroupItem>
              <h4>
                <b>{"\uFF0B"}</b> Create a new note
              </h4>
            </ListGroupItem>
          </LinkContainer>
    );
  }

  renderSourcesList(sources) {
    return [{}].concat(sources).map(
      (source, i) =>
        i !== 0
          ? <LinkContainer
            key={source.sourceId}
            to={`/sources/${source.sourceId}`}
          >
            <ListGroupItem header={source.domain.trim().split("\n")[0]}>
              {"Created: " + new Date(source.createdAt).toLocaleString() + "\n" + "Cost: $" + (source.cost || 0)}
            </ListGroupItem>
          </LinkContainer>
          : <LinkContainer
            key="new"
            to="/sources/new"
          >
            <ListGroupItem>
              <h4>
                <b>{"\uFF0B"}</b> Create a new source
              </h4>
            </ListGroupItem>
          </LinkContainer>
    );
  }

  renderLander() {
    return (
      /*
      <Grid>
        <Row>
          <Col xs={12} md={6}>
            <div className="first">
              <h1>Just in time image processing</h1>
              <p>No more preprocessing of images before </p>
            </div>
          </Col>
        </Row>
      </Grid>
      */
      <div className="lander">
        <h1>alter.sh</h1>
        <p>Just in time image processing</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  renderNotes() {
    return (
      <div className="notes">
        <PageHeader>Your Notes</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderNotesList(this.state.notes)}
        </ListGroup>
      </div>
    );
  }

  renderSources() {
    return (
      <div className="sources" key="sources">
        <PageHeader>Your Sources</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderSourcesList(this.state.sources)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? [this.renderSources()] : this.renderLander()}
      </div>
    );
  }
}
