import React, {Component} from "react";
import {API} from "aws-amplify";
import {FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import {s3Upload} from "../libs/awsLib";
import config from "../config";
import "./Notes.css";

export default class Sources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      isDeleting: null,
      source: null,
      domain: "",
      accessKeyId: "",
      secretAccessKey: "",
      bucketRegion: "",
      bucketName: "",
      pathPrefix: "",
    };
  }

  async componentDidMount() {
    try {
      const source = await this.getSource();
      const { domain, accessKeyId, secretAccessKey, bucketRegion, bucketName, pathPrefix } = source;


      this.setState({
        source,
        domain,
        accessKeyId,
        secretAccessKey,
        bucketRegion,
        bucketName,
        pathPrefix,
      });
    } catch (e) {
      alert(e);
    }
  }

  getSource() {
    return API.get("sources", `/sources/${this.props.match.params.id}`);
  }

  saveSource(source) {
    return API.put("sources", `/sources/${this.props.match.params.id}`, {
      body: source
    });
  }

  deleteSource() {
    return API.del("sources", `/sources/${this.props.match.params.id}`);
  }

  validateForm() {
    // Todo: Fix validation
    return this.state.domain.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.saveSource({
        domain: this.state.domain,
        accessKeyId: this.state.accessKeyId,
        secretAccessKey: this.state.secretAccessKey,
        bucketRegion: this.state.bucketRegion,
        bucketName: this.state.bucketName,
        pathPrefix: this.state.pathPrefix,
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this source?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteSource();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  render() {
    return (
      <div className="Sources">
        {this.state.source &&
        <form onSubmit={this.handleSubmit}>
          <ControlLabel>Domain</ControlLabel>
          <FormGroup controlId="domain">
            <FormControl
              readOnly
              value={this.state.domain}
              componentClass="input"
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="accessKeyId">
            <ControlLabel>AWS Access Key Id</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.accessKeyId}
              componentClass="input"
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="secretAccessKey">
            <ControlLabel>AWS Secret Access Key</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.secretAccessKey}
              componentClass="input"
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="bucketRegion">
            <ControlLabel>Region</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.bucketRegion}
              componentClass="select"
            >
              <option value="ap-northeast-1">ap-northeast-1</option>
              <option value="ap-northeast-2">ap-northeast-2</option>
              <option value="ap-south-1">ap-south-1</option>
              <option value="ap-southeast-1">ap-southeast-1</option>
              <option value="ap-southeast-2">ap-southeast-2</option>
              <option value="ca-central-1">ca-central-1</option>
              <option value="eu-central-1">eu-central-1</option>
              <option value="eu-north-1">eu-north-1</option>
              <option value="eu-west-1">eu-west-1</option>
              <option value="eu-west-2">eu-west-2</option>
              <option value="eu-west-3">eu-west-3</option>
              <option value="sa-east-1">sa-east-1</option>
              <option value="us-east-1">us-east-1</option>
              <option value="us-east-2">us-east-2</option>
              <option value="us-west-1">us-west-1</option>
              <option value="us-west-2">us-west-2</option>
            </FormControl>
          </FormGroup>
          <FormGroup controlId="bucketName">
            <ControlLabel>Bucket name</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.bucketName}
              componentClass="input"
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="pathPrefix">
            <ControlLabel>Path prefix</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.pathPrefix}
              componentClass="input"
              type="text"
            />
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Save"
            loadingText="Saving…"
          />
          <LoaderButton
            block
            bsStyle="danger"
            bsSize="large"
            isLoading={this.state.isDeleting}
            onClick={this.handleDelete}
            text="Delete"
            loadingText="Deleting…"
          />
        </form>}
      </div>
    );
  }
}
